import React, { useState, useRef } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_KEY } from '../components/Include';


const TextEditor=(props:any)=> {
    const editorRef = useRef('');
    const [contentEditor, setContentEditor] = useState();


/* 
  file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
  edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
  view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
  insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
  format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat' },
  tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
  table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
  help: { title: 'Help', items: 'help' } */


  return (
    <>
<Editor
        onInit={(evt:any, editor:any) => editorRef.current = editor}
        apiKey={TINYMCE_KEY}
        tinymceScriptSrc={'https://cdnjs.cloudflare.com/ajax/libs/tinymce/6.7.0/tinymce.min.js'} 
  
        init={{
          height: props.height,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview','pagebreak',
            'anchor', 'searchreplace', 'visualblocks', 'visualchars', 'fullscreen', 'emoticons',
            'insertdatetime', 'media', 'table', 'template', 'help', 'wordcount', 'codesample','MathML'
          ],
          toolbar: 'undo redo | blocks | image link media codesample MathML |table|' +
            'bold italic superscript subscript |charmap emoticons | forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat ',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
          
       
        }}
        value={props.value}
        onEditorChange={props.onEditorChange}
      />


  </>
  )
}

export default TextEditor