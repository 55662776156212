import React from 'react'

function Footer() {
  return (
    <footer className="footer"> 
    <div className="container"> 
    <div className="row align-items-center flex-row-reverse"> 
    <div className="col-md-12 col-sm-12 text-center"> Copyright © 
    <span >2024</span> <a href="#">The Traffic NG Inc.</a> Designed with <span className="ri ri-heart-fill text-danger"></span> All rights reserved. </div> </div> </div> </footer> 
  )
}

export default Footer